import { setCookie } from '@pepita/lotrash';
import { serialize } from '@pepita/querystring';
import type { CredentialResponse } from 'google-one-tap';
import type { useSetAtom } from 'jotai';

import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import { http } from 'src/utils/client/http';

const oAuth: ProductConfig['oAuth'] = getProductConfig('oAuth');

let isGoogleOneTapInitialized = false;

export const initGoogleOneTap = (
  setDisplayGOT: ReturnType<typeof useSetAtom>,
  onSuccess: (res: GotHandleResponse) => void,
  onFailure: (res?: GotHandleResponse) => void,
  trackLoginStart: () => void
) => {
  if (oAuth && window.google && !isGoogleOneTapInitialized) {
    window.google.accounts.id.initialize({
      client_id: oAuth.google.clientId,
      callback: handleCallback(trackLoginStart, onSuccess, onFailure),
      auto_select: false,
    });

    window.google.accounts.id.prompt((notification) => {
      if (notification.getMomentType() !== 'display') {
        setDisplayGOT('hidden');
      } else {
        setDisplayGOT('visible');
      }
    });

    isGoogleOneTapInitialized = true;
  }
};

const handleCallback =
  (
    trackLoginStart: () => void,
    onSuccess: (res: GotHandleResponse) => void,
    onFailure: (res?: GotHandleResponse) => void
  ) =>
  (response: CredentialResponse) => {
    if (response) {
      trackLoginStart();

      return http
        .post('/singleSignOn.php', {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: serialize({
            action: 'parserequesttoken',
            mode: 'oauth',
            accountType: 'google',
            persistent: 1,
            code: response.credential,
            oneTap: 1,
            v: 2,
          }),
        })
        .json<GotHandleResponse>()
        .then((res) => {
          //to resolve https://sentry.pepita.io/organizations/b2c/issues/93190/events/b283fe0d7e994bfeb17969dcc9e08784/events/?project=44&statsPeriod=14d
          if (res.utente) {
            setCookie('IMMSESSID', res.utente.session_token);
            onSuccess(res);
          } else {
            onFailure(res);
          }
        })
        .catch(() => {
          onFailure();
        });
    }
  };

export interface GotHandleResponse {
  accountType: string;
  action: string;
  context: string;
  error?: string;
  persistent: string;
  userType: number;
  utente: {
    session_token: string;
    uuid: string | null;
    email: string | null;
    phone: string | null;
  };
  trackSSOAccess?: string;
  trackSSORegistration?: string;
}
